import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/my-bloody-valentine-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1981 slasher movie, My Bloody Valentine"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 35</h1>
                    <h2>My Bloody Valentine</h2>
                    <h3>February 14, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> George Mihalka // <b>Starring:</b> Paul Kelman &amp; Neil Affleck</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/35-My-Bloody-Valentine-Bonus-e2fhsb1/a-aaunrg8" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe episode 35, My Bloody Valentie"></iframe>
                        </div>
                        <p><Link to="/transcripts/my-bloody-valentine">Read the episode transcript</Link></p>
                        <p>Listen up, hosers! Dave and Bryan love you and have sent you this Valentine's Day love letter to 1981's Canadian slasher movie classic, My Bloody Valentine. It's better than you expect it to be, with a cast of people that you actually grow to like. Sure, it's another slice and dice picture from the golden age of slashers but not too many of them paid this much attention to the craft of telling a story. How does this movie hold up to modern standards? You're going to have to listen to find out.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/dawn-of-the-dead">Next Episode</Link></li>
                        <li><Link to="/episodes/ganja-and-hess">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)